import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArchivePermissionDirective } from './archive-permission.directive';



@NgModule({
  declarations: [
    ArchivePermissionDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ArchivePermissionDirective
  ]
})

export class DirectivesModule { }
