import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { NgxMaskModule } from 'ngx-mask';
import { NgbDropdownModule, NgbTooltipModule, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from './pipes/pipes.module';

import { AlertComponent } from './alert/alert.component';
import { ChronometerComponent } from './chronometer/chronometer.component';
import { SettingsComponent } from './chronometer/settings/settings.component';
import { ColorsComponent } from './colors/colors.component';
import { HeaderButtonsComponent } from './header-buttons/header-buttons.component';
import { LoadingComponent } from './loading/loading.component';
import { ToastComponent } from './toast/toast.component';
import { SubscriptionAlertComponent } from './subscription-alert/subscription-alert.component';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import { ExamComponent } from './modal/exam/exam.component';
import { ProfileComponent } from './modal/profile/profile.component';
import { StudyComponent } from './modal/study/study.component';
import { VideoTutorialComponent } from './modal/video-tutorial/video-tutorial.component';
import { DirectivesModule } from './directives/directives.module';


@NgModule({
  declarations: [
    AlertComponent,
    ChronometerComponent,
    SettingsComponent,
    ColorsComponent,
    LoadingComponent,
    ToastComponent,
    HeaderButtonsComponent,
    SubscriptionAlertComponent,
    ExamComponent,
    ProfileComponent,
    StudyComponent,
    VideoTutorialComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PipesModule,
    DirectivesModule,
    NgbDropdownModule,
    NgxMaskModule.forRoot(),
    NgbTooltipModule,
    NgbTypeaheadModule,
    NgHcaptchaModule.forRoot({
      siteKey: '9058f1c4-fcaf-4075-b862-3bd647b51e0a',
      languageCode: 'pt'
    })
  ],
  exports: [
    AlertComponent,
    ChronometerComponent,
    ColorsComponent,
    HeaderButtonsComponent,
    LoadingComponent,
    ToastComponent,
    SubscriptionAlertComponent,
    ExamComponent,
    ProfileComponent,
    StudyComponent,
    VideoTutorialComponent
  ]
})
export class SharedModule { }
