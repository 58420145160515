import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnumTextPipe } from './enum-text.pipe';
import { StudyCategoryPipe } from './study-category.pipe';
import { TimeSincePipe } from './time-since.pipe';
import { LetterColorPipe } from './letter-color.pipe';


@NgModule({
  declarations: [
    EnumTextPipe,
    StudyCategoryPipe,
    TimeSincePipe,
    LetterColorPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    EnumTextPipe,
    StudyCategoryPipe,
    TimeSincePipe,
    LetterColorPipe
  ]
})
export class PipesModule { }
